import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useIntersectionObserver, useWindowSize } from '@react-hookz/web';
import {
  Autoplay,
  EffectFade,
  FreeMode,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper/modules';
import { Swiper as SwiperJS, SwiperSlide } from 'swiper/react';

import { useSpacingValue } from '@/lib/useSpacingValue';

import type {
  blockSpacingOptions,
  componentSpacingOptions,
} from '@/types/measures';
import type { ReactElement } from 'react';
import type { NavigationOptions, SwiperOptions } from 'swiper/types';

import 'swiper/css';
import 'swiper/css/free-mode';

import { screen } from '@/components/common/breakpoints';
import {
  getClosestBreakpoint,
  renderNavigation,
  renderPagination,
} from '@/components/common/Swiper/Custom/Pagination/utils';
import { getBreakpointPixelValue } from '@/lib/utils';

import type { BreakpointKeys } from '@/components/common/breakpoints';

export interface SwiperProps {
  className?: string;
  autoplay?: {
    // enabled: true to turn on autoplay (default transition speed is 2500ms)
    enabled?: boolean;

    // speed: controls the transition speed in ms
    speed?: number;

    showProgress?: boolean;
  };

  // centeredSlides: enable to always center the slide which is the focus, other slides will be cutoff depending on the container size
  centeredSlides?: boolean;

  // effect: alters the transition style between slides
  effect?: 'slide' | 'fade';

  // freeMode: enable to allow smooth, non-snapping movement between slides which encorporates velocity and momentum of a swipe
  freeMode?: boolean;

  navigation?: {
    // enabled: true to turn on controls as a feature
    enabled?: boolean;

    // type: controls which navigation component to use
    type?: 'default' | 'colourful';
  };

  pagination?: {
    // enabled: true to turn on pagination as a feature
    enabled?: boolean;

    // type: controls which pagination component to use
    type?: 'detailed' | 'pip' | 'progressbar' | 'simple';

    // a custom component that can be inserted into the pagination button - this could be a company logo to depict that comapny's testimonial for example
    components?: ReactElement[];
  };

  // slides: controls the content of the carousel
  slides: ReactElement[];

  // slidesPerView: how many slides are visible on the screen at one time at all the breakpoints
  slidesPerView?: Record<string, number>;

  // slidesPerGroup: Set numbers of slides to define and enable group sliding. Useful to use with slidesPerView > 1
  slidesPerGroup?: Record<string, number>;

  // spaceBetween: this controls the gap between the slides: it becomes the margin-right of a slide
  spaceBetween?: componentSpacingOptions | blockSpacingOptions | 0;

  // Allows to set different parameter for different responsive breakpoints (screen sizes).
  breakpoints?: {
    base?: SwiperOptions;
    sm?: SwiperOptions;
    md?: SwiperOptions;
    lg?: SwiperOptions;
    xl?: SwiperOptions;
  };
}

export const Swiper = ({
  autoplay,
  centeredSlides,
  effect,
  freeMode = false,
  navigation,
  pagination,
  slides,
  slidesPerView = {
    max: 1.01,
  },
  slidesPerGroup = {
    max: 1,
  },
  spaceBetween = 0,
  className,
  breakpoints,
}: SwiperProps) => {
  const swiperRef = useRef(null);
  const nextButtonRef = useRef(null);
  const prevButtonRef = useRef(null);
  const progressBarRef = useRef(null);
  const mobileProgressBarRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState(null);

  const swiperViewport = useIntersectionObserver(swiperRef.current, {
    threshold: [0],
    rootMargin: '300px',
  });

  const { width: screenWidth } = useWindowSize();

  useEffect(() => {
    // .pause()/.resume() seems to be buggy
    if (autoplay?.enabled) {
      if (!swiperViewport?.isIntersecting) {
        swiperInstance?.autoplay.stop();
      } else {
        swiperInstance?.autoplay.start();
      }
    }
  }, [autoplay?.enabled, swiperInstance, swiperViewport?.isIntersecting]);

  const autoplaySettings = {
    delay: autoplay?.speed ?? 5000,
    disableOnInteraction: true,
    pauseOnMouseEnter: true,
    stopOnLastSlide: true,
  };

  const slidesPerViewValue = getClosestBreakpoint(screenWidth, slidesPerView);
  const slidesPerGroupValue = getClosestBreakpoint(screenWidth, slidesPerGroup);

  const paginationSettings = {
    clickable: true,
  };

  const progressBarSettings = {
    enabled: true,
    draggable: true,
    dragClass: 'progress-bar-inner',
    dragSize: 32,
    el: '.progressbar > .styled-progress-bar',
  };

  const navigationCollectionettings: NavigationOptions = {
    nextEl: nextButtonRef.current,
    prevEl: prevButtonRef.current,
  };

  const onAutoplayTimeLeft = (_s, _time, progress) => {
    if (progress >= 0) {
      progressBarRef.current?.style.setProperty('--progress', 1 - progress);
      mobileProgressBarRef.current?.style.setProperty(
        '--progress',
        1 - progress,
      );
    }
  };

  const swiperBreakpointsResolved = () => {
    const breakpointsResolved = {};
    for (const [key, value] of Object.entries(breakpoints)) {
      breakpointsResolved[getBreakpointPixelValue(key as BreakpointKeys)] =
        value;
    }
    return breakpointsResolved;
  };

  return (
    <>
      <Detector ref={swiperRef} />
      <SwiperJS
        autoplay={autoplay?.enabled && autoplaySettings}
        centeredSlides={centeredSlides}
        effect={effect}
        freeMode={freeMode}
        modules={[
          Autoplay,
          Pagination,
          Navigation,
          FreeMode,
          EffectFade,
          Scrollbar,
        ]}
        navigation={navigation?.enabled && navigationCollectionettings}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        pagination={pagination?.enabled && paginationSettings}
        slidesPerView={slidesPerViewValue}
        slidesPerGroup={slidesPerGroupValue}
        spaceBetween={useSpacingValue(spaceBetween)}
        watchSlidesProgress={true}
        scrollbar={pagination?.type === 'progressbar' && progressBarSettings}
        breakpoints={!!breakpoints && swiperBreakpointsResolved()}
      >
        {slides.map((slide) => {
          return (
            <SwiperSlide className={className} key={slide?.key}>
              {slide}
            </SwiperSlide>
          );
        })}
        <ControlsContainer
          className={pagination?.type}
          showControls={slidesPerViewValue < slides.length}
        >
          {pagination?.enabled &&
            renderPagination(
              pagination?.type || 'pip',
              slides,
              slidesPerViewValue,
              pagination,
              progressBarRef,
              mobileProgressBarRef,
            )}
          {navigation?.enabled &&
            renderNavigation(
              navigation?.type || 'default',
              prevButtonRef,
              nextButtonRef,
            )}
        </ControlsContainer>
      </SwiperJS>
    </>
  );
};

const ControlsContainer = styled.div<{ showControls: boolean }>`
  display: ${({ showControls }) => (showControls ? 'flex' : 'none')};
  justify-content: space-between;

  &.detailed {
    margin-top: var(--space-block-md);

    ${screen.md} {
      margin-top: var(--space-block-lg);
    }
  }

  &.progressbar {
    padding-top: var(--space-block-md);
  }

  &.simple,
  &.pip {
    padding: var(--space-block-md) var(--space-component-sm) 0
      var(--space-component-xl);
  }

  &.detailed,
  &.simple,
  &.progressbar {
    justify-content: center;
  }
`;

const Detector = styled.div`
  position: relative;
  height: 1px;
`;
