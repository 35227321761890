import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { Image } from '@/components/common/image';
import { Paragraph } from '@/components/common/MarkUp';
import { Swiper } from '@/components/common/Swiper';
import { Section } from '@/components/layout/Section';
import { Stack } from '@/components/layout/Stack';

import 'swiper/css/effect-fade';

import { css } from '@emotion/react';

import { FeatureCard } from '@/components/common/FeatureCard';
import { QuoteCard } from '@/components/common/QuoteCard';
import { WistiaVideoPlayer } from '@/components/common/WistiaComponents/WistiaVideoPlayer';
import { useMediaQuery } from '@/lib/useMediaQuery';

import type { ResponsiveImage } from '@/components/common/ResponsiveImage';
import type { SanityShowcaseCarouselType } from '@/types/sanity';

export interface ShowcaseCarouselProps extends SanityShowcaseCarouselType {}

export const ShowcaseCarousel = ({
  slides,
  autoplay,
  blockSpacing,
  paddingAdjustment,
}: ShowcaseCarouselProps) => {
  const isDesktop = useMediaQuery(screen.mdQuery);

  const getSlideEffect = () => {
    if (!isDesktop) return 'slide';
    if (slides[0]._type === 'fullImage') return 'fade';
    if (slides[0]._type === 'video') return 'fade';
    if (slides[0]._type === 'testimonial') {
      if (isDesktop) {
        return 'fade';
      } else return 'slide';
    }
  };

  const slideComponents = slides.map((slide) => {
    switch (slide._type) {
      case 'fullImage':
        return (
          <FeatureCard
            key={slide._key}
            responsiveImage={
              slide?.responsiveImage as unknown as ResponsiveImage
            }
            surfaceColour={slide?.surfaceColour?.token}
            containerSize={{
              base: '592px',
              sm: '864px',
              md: '992px',
              lg: '1280px',
            }}
          />
        );
      case 'testimonial':
        return (
          <QuoteCard
            key={slide._key}
            company={slide.testimonial.company}
            testimonial={slide.testimonial.testimonialQuote}
            backgroundColour={slide.backgroundColour}
            decoration={slide?.decoration}
            ctaButton={slide.testimonial.ctaButton as never}
          />
        );
      case 'video': {
        const videoProps = {
          wistiaPopover: {
            videoTitle: slide?.videoTitle,
            videoLength: slide?.videoLength,
            trackingTag: slide?.trackingTag,
            videoId: slide?.videoId,
          },
          image: slide?.image,
        };

        return (
          <WistiaContainer>
            <WistiaVideoPlayer {...videoProps} />
          </WistiaContainer>
        );
      }
    }
  });

  const getPaginationComponents = () =>
    slides.map((slide) => {
      return (
        <Tab key={slide._key} spacing={'--space-component-md'}>
          {slide?.paginationImage?.asset && (
            <PaginationImage>
              <Image {...slide.paginationImage} alt="" />
            </PaginationImage>
          )}
          {slide?.heading && (
            <StyledParagraph
              size={isDesktop ? 'heading-sm' : 'heading-md'}
              colour={isDesktop ? '--text-warm-base' : '--text-strong'}
            >
              {slide.heading}
            </StyledParagraph>
          )}
          {slide?.description && (
            <StyledParagraph size="body-md" colour="--text-warm-base">
              {slide.description}
            </StyledParagraph>
          )}
        </Tab>
      );
    });

  const swiperSharedProps = {
    slides: slideComponents,
    pagination: {
      enabled: true,
      type: 'detailed',
      components: getPaginationComponents(),
    },
    autoplay: {
      enabled: autoplay?.enable,
      speed: autoplay?.speed,
      showProgress: true,
    },
  } as const;

  return (
    <Section
      className="showcase-container"
      verticalPadding={paddingAdjustment ?? true}
      spacing={blockSpacing}
    >
      <ShowcaseContainer type={slides[0]._type}>
        {/* necessary as 'effect' prop can't be updated at breakpoints */}
        {isDesktop && (
          <Swiper
            {...swiperSharedProps}
            effect={getSlideEffect()}
            slidesPerView={{
              max: 1.01,
            }}
            slidesPerGroup={{
              max: 1,
            }}
          />
        )}
        {!isDesktop && (
          <Swiper
            {...swiperSharedProps}
            effect={getSlideEffect()}
            spaceBetween="--space-component-lg"
            className={`${slides[0]._type} showcase-carousel`}
            slidesPerView={{
              max: 1.01,
            }}
            slidesPerGroup={{
              max: 1,
            }}
          />
        )}
      </ShowcaseContainer>
    </Section>
  );
};

const ShowcaseContainer = styled.div<{
  type: 'fullImage' | 'testimonial' | 'video';
}>`
  .swiper-fade {
    .swiper-slide-visible {
      opacity: 1;
    }

    .swiper-slide:not(.swiper-slide-visible) {
      opacity: 0 !important;
    }
  }

  .swiper-slide {
    height: auto;
  }

  ${({ type }) =>
    type === 'fullImage' ||
    (type === 'video' &&
      css`
        .swiper {
          overflow: visible;
        }
        .swiper-slide {
          img {
            height: 400px;
            ${screen.sm} {
              height: 480px;
            }
            ${screen.xl} {
              height: 640px;
            }
          }
        }
      `)}

  ${({ type }) =>
    type === 'testimonial' &&
    css`
      .swiper {
        overflow: visible;

        ${screen.md} {
          width: calc(100% - (100% / 6));
        }

        ${screen.lg} {
          max-width: 846px;
          width: 66%;
        }

        .swiper-slide > div {
          margin: 0;
          width: 100%;
        }
      }
    `};
`;

const Tab = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: var(--space-component-xl);
  margin-top: var(--space-component-xl);
  padding-left: var(--space-component-sm);
  padding-right: var(--space-component-sm);
`;

const PaginationImage = styled.div`
  img {
    height: 48px;
    ${screen.md} {
      height: 56px;
    }
    ${screen.lg} {
      height: 64px;
    }
  }
`;

const StyledParagraph = styled(Paragraph)`
  transition: color 0.3s;
`;

const WistiaContainer = styled.div`
  justify-self: center;

  img {
    max-height: 400px;
  }
  > div {
    border-radius: 0px;
  }
`;
