import type { SanityAbTestFormType, SanityAbTestType } from '@/types/sanity';

type AbData = SanityAbTestFormType | SanityAbTestType;

interface AbTestProps {
  [x: string]: any;
  abTest: AbData;
  component: React.FC<any>;
}

export const AbTestWrapper = ({ abTest, component, ...props }: AbTestProps) => {
  if (!abTest) {
    console.error(new Error('No A/B test defined'));
    return null;
  }

  const Component = component;
  const { uuid, control, variations } = abTest;

  return (
    <div id={`dy-${uuid}`}>
      {!!control && (
        <div
          id={`dy-${uuid}-control`}
          className="dy-blocks-container"
          style={{ display: 'block' }}
        >
          <Component {...control} {...props} DYId={`dy-${uuid}-control`} />
        </div>
      )}

      {!!variations &&
        variations.map((variation, index) => (
          <div
            key={variation._key}
            id={`dy-${uuid}-variation-${index + 1}`}
            className="dy-blocks-container"
            style={{ display: 'none' }}
          >
            <Component
              {...variation}
              {...props}
              DYId={`dy-${uuid}-variation-${index + 1}`}
            />
          </div>
        ))}
    </div>
  );
};
